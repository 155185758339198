.App {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    max-width: 600px;
}

main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

header {
    background: darkolivegreen;
    padding: 1rem;
    color: whitesmoke;
    border-radius: 10px;
}