.expenseItem {
    display: flex;
    justify-content: space-between;
    background: goldenrod;
    padding: 1rem;
    border-radius: 10px;
    color: brown;
    font-size: 1.4rem;
    align-items: center;
    gap: 2rem;
}

.title {
    margin-right: auto;
    text-align: right;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    color: black;
}

.amount {
    background: firebrick;
    padding: 10px 15px;
    color: whitesmoke;
    border-radius: 10px;
    width: 100px;
}

.date {
    background: wheat;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    gap: 5px;
    font-size: 1rem;
    flex-direction: column;
    width: 100px;
}

.month {
    color: black;
}

.day {
    font-size: 2rem;
}