.expenseForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.control {
    display: flex;
    gap: 1rem;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

label {
    font-size: 1.4rem;
}

input {
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
    border: none;
    text-align: left;
    width: 60%;
}

.btnGroup {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;
    padding: 10px;
}

button {
    padding: 10px 15px;
    border-radius: 10px;
    background: black;
    color: whitesmoke;
    font-size: 1.1rem;
    cursor: pointer;
}