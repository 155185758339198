@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,700;1,300&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-align: center;
}

body {
    font-family: 'Josefin Sans', sans-serif;
    margin: 2rem;
}